import * as yup from 'yup';

import { VoteChoice, ValidationErrorCode, VoteMethod } from '../types';

const { MISSING, INVALID } = ValidationErrorCode;

export const voteSchema = yup.object({
  coOwnerId: yup.string().required(),
  generalAssemblyId: yup.string().uuid().required(),
  resolutionId: yup.string().required(),
  date: yup.string().required({ field: 'date', code: MISSING }),
  choice: yup
    .string()
    .oneOf(Object.values(VoteChoice), { field: 'choice', code: INVALID })
    .required({ field: 'choice', code: MISSING }),
  voteMethod: yup.string().oneOf(Object.values(VoteMethod)),
  representedBy: yup.string(),
  allocationKeyId: yup.string(),
  voteId: yup.string().uuid().required(),
  manual: yup.boolean(),
});
