import { voteSchema } from 'backend/schemas/vote';
import { Vote } from 'backend/types';

import {
  generateCreateDocumentHelper,
  generateUpdateDocumentHelper,
  generateDeleteDocumentHelper,
  generateGetCollection,
} from './utils';

export const getCoOwnerVotesPath = (
  generalAssemblyId: string,
  coOwnerId: string,
): string =>
  `generalAssemblies/${generalAssemblyId}/coOwners/${coOwnerId}/votes`;

export const getCoOwnerVotes = generateGetCollection<Vote>('resolutionId');

export const addCoOwnerVote = generateCreateDocumentHelper(
  voteSchema,
  'resolutionId',
  [],
);

export const updateCoOwnerVote = generateUpdateDocumentHelper(
  voteSchema,
  'resolutionId',
);

export const deleteCoOwnerVote = generateDeleteDocumentHelper();
