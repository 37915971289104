import * as XLSX from 'xlsx';

import { AllocationKey, ReportingErrorState } from 'backend/types';
import { CoOwner, ValidationErrorCode } from 'backend/types';
import {
  createAllocationKey,
  createCoOwner,
  deleteAllocationKey,
  deleteCoOwner,
  getAllocationKeys,
  getCoOwners,
} from 'services/db';

export enum RowType {
  ALLOCATION_KEY = 'CLE',
  CO_OWNER = 'COP',
  NONE = 'NONE',
}

export const coOwnerImportFields = [
  'coOwnerId',
  'civility',
  'name',
  'address',
  'postalCode',
  'city',
  'country',
  'phoneNumber',
  'lots',
  'email',
] as [
  'coOwnerId',
  'civility',
  'name',
  'address',
  'postalCode',
  'city',
  'country',
  'phoneNumber',
  'lots',
  'email',
];

export interface CoOwnersParsedData {
  allocationKeys: Record<string, AllocationKey>;
  coOwners: Record<string, Pick<CoOwner, typeof coOwnerImportFields[number]>>;
  errors: {
    rowType: RowType;
    row?: number;
    field: string;
    code: ValidationErrorCode;
    value?: string;
    state?: ReportingErrorState;
  }[];
}

export const parseFile = (
  file: File,
  parsingFunction: (
    data: (string | number | undefined)[][] | any,
  ) => CoOwnersParsedData,
): Promise<CoOwnersParsedData> =>
  new Promise<CoOwnersParsedData>((resolve) => {
    // Cas général (XLSX)
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      const [wsname] = wb.SheetNames;
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* Process data */
      resolve(parsingFunction(data as (string | number)[][]));
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });

export const clearGeneralAssemblyAllocationKeysAndCoOwners = async (
  coOwnersPath: string,
  allocationKeysPath: string,
) =>
  // Firestore doesn't provide a function to delete a collection
  // (see: https://firebase.google.com/docs/firestore/manage-data/delete-data#collections)
  // so we have to delete every items in collections
  Promise.all([
    ...Object.values(await getAllocationKeys(allocationKeysPath)).map(
      ({ allocationKeyId }) =>
        deleteAllocationKey(allocationKeysPath, allocationKeyId),
    ),
    ...Object.values(await getCoOwners(coOwnersPath)).map(({ coOwnerId }) =>
      deleteCoOwner(coOwnersPath, coOwnerId),
    ),
  ]);

export const importCoOwners = async (
  parsedData: CoOwnersParsedData,
  coOwnersPath: string,
  allocationKeysPath: string,
  updateGeneralAssembly: (data: any) => Promise<void>,
) => {
  const { coOwners, allocationKeys } = parsedData;

  await Promise.all<string | void>([
    // save coOwners
    ...Object.values(coOwners).map((coOwner) =>
      createCoOwner(coOwnersPath, {
        ...coOwner,
        isExternalRepresentative: false,
      }),
    ),
    // save allocationKeys
    ...Object.values(allocationKeys).map((allocationKey, index) => {
      if (index === 0) {
        allocationKey.isPrimaryKey = true;
      }
      return createAllocationKey(allocationKeysPath, allocationKey);
    }),

    // reset counters
    updateGeneralAssembly({
      representedTantiemes: 0,
      coOwnersEarlyVotingCount: 0,
    }),
  ]);
};
