import * as yup from 'yup';

import {
  GeneralAssemblyStatus,
  GeneralAssemblyType,
} from '../types/generalAssembly';

export const generalAssemblySchema = yup.object({
  generalAssemblyId: yup.string().uuid().required(),
  syndicId: yup.string().uuid().required(),
  status: yup
    .string()
    .oneOf(Object.values(GeneralAssemblyStatus))
    .default(GeneralAssemblyStatus.DRAFT),
  coOwnershipName: yup.string().required(),
  syndicName: yup.string().required(),
  type: yup.string().oneOf(Object.values(GeneralAssemblyType)).required(),
  /**
   * @debt validation "add IsoDate validation"
   */
  date: yup.string().required(),
  startDate: yup.string(),
  closeDate: yup.string(),
  address: yup.string(),
  publicNote: yup.string(),
  privateNote: yup.string(),
  doesTranscriptDisplayElectionCoOwnerName: yup.boolean(),
  doesTranscriptDisplaysOnlyObjectors: yup.boolean(),
  isTest: yup.boolean(),
  isRemoteOnly: yup.boolean(),
  maxCoOwnershipRepresentationPercentage: yup
    .number()
    .integer()
    .min(0)
    .max(100),
  maxCoOwnerRepresentationsCount: yup.number().integer().min(1),
  representedTantiemes: yup.number().integer().default(0),
  coOwnersEarlyVotingCount: yup.number().integer().default(0),
  loginCode: yup.string().length(6),
  presidentCoOwnerId: yup.string(),
  secretaryCoOwnerId: yup.string(),
  secretary: yup.string(),
  secretarySignature: yup.string(),
  scrutineerCoOwnersIds: yup.array().of(yup.string().required()),
  unionConcilCoOwnersIds: yup.array().of(yup.string().required()),
  location: yup.string(),
  longitude: yup.number(),
  latitude: yup.number(),
  transcriptOddColor: yup.string(),
  transcriptEvenColor: yup.string(),
  transcriptHtmlContent: yup.string(),
  attendanceSheetHtmlContent: yup.string(),
  transcriptFixedModal: yup.boolean(),
  transcriptDisplayMissingCoOwners: yup.boolean(),
  transcriptDisplayPresentCoOwners: yup.boolean(),
  rate: yup.number().min(0).max(5),
});
