import * as yup from 'yup';

import { ValidationErrorCode } from '../types/yup';

export const allocationKeySchema = yup.object({
  allocationKeyId: yup.string().required({
    field: 'allocationKeyId',
    code: ValidationErrorCode.MISSING,
  }),
  name: yup.string().required({
    field: 'name',
    code: ValidationErrorCode.MISSING,
  }),
  tantiemes: yup
    .number()
    .typeError({
      field: 'tantiemes',
      code: ValidationErrorCode.INVALID_TYPE,
    })
    .required({
      field: 'tantiemes',
      code: ValidationErrorCode.MISSING,
    }),
  isPrimaryKey: yup.boolean().default(() => false),
});
