import { ResolutionType } from '../types';

const { PRESIDENT, SECRETARY, SCRUTINEER, UNION_CONCIL, COMMENT } =
  ResolutionType;

export const isCandidacyResolution = (
  type: string | undefined,
): type is
  | ResolutionType.PRESIDENT
  | ResolutionType.SECRETARY
  | ResolutionType.SCRUTINEER
  | ResolutionType.UNION_CONCIL =>
  [PRESIDENT, SECRETARY, SCRUTINEER, UNION_CONCIL].includes(
    type as ResolutionType,
  );

export const isCommentResolution = (
  type: string | undefined,
): type is ResolutionType.COMMENT => type === COMMENT;

export const allowCustomCandidate = (type: string | undefined): boolean =>
  type !== 'COMMENT' && type !== 'GENERAL';
