import * as yup from 'yup';
import { A } from 'ts-toolbelt';

import { fuego } from 'services/firebase/fuego';

export const validatePartial = <S extends yup.AnyObjectSchema>(
  schema: S,
  object: Record<string, unknown>,
): A.Compute<Partial<yup.Asserts<S>>> =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        value instanceof fuego.firestore.FieldValue
          ? value
          : schema.validateSyncAt(key, object),
    }),
    {} as A.Compute<Partial<yup.Asserts<S>>>,
  );
