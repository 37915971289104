import * as yup from 'yup';
import { A } from 'ts-toolbelt';

import { generalAssemblySchema } from '../schemas/generalAssembly';

export enum GeneralAssemblyType {
  COOWNERSHIP = 'COOWNERSHIP',
  ASSOCIATION = 'ASSOCIATION',
  COMPANY = 'COMPANY',
}

export enum GeneralAssemblyStatus {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  CO_OWNERS_CONVOKED = 'CO_OWNERS_CONVOKED',
  LINKS_SENT = 'LINKS_SENT',
  OPEN = 'OPEN',
  ON_GOING = 'ON_GOING',
  CLOSED = 'CLOSED',
}

export type GeneralAssembly = A.Compute<
  yup.Asserts<typeof generalAssemblySchema>
>;
