import { resolutionSchema } from 'backend/schemas/resolution';
import { Resolution } from 'backend/types/resolution';

import {
  generateCreateDocumentHelper,
  generateDeleteDocumentHelper,
  generateGetCollection,
} from './utils';
import { generateUpdateDocumentHelper } from './utils/generateUpdateDocumentHelper';

export const createResolution = generateCreateDocumentHelper(
  resolutionSchema,
  'resolutionId',
  [
    'resolutionId',
    'voteStatus',
    'tantiemesInFavor',
    'tantiemesAgainst',
    'tantiemesAbstention',
    'coOwnersInFavor',
    'coOwnersAgainst',
    'coOwnersAbstention',
    'votingCoOwnersTantiemes',
  ],
);

export const getResolution = async (
  generalAssemblyId: string,
  resolutionId: string,
): Promise<Resolution> => {
  const resolutionsPath = getResolutionsPath(generalAssemblyId);
  const resolutions = await getResolutions(resolutionsPath);
  return resolutions[resolutionId];
};

export const getResolutions = generateGetCollection<Resolution>('resolutionId');

export const updateResolution = generateUpdateDocumentHelper(
  resolutionSchema,
  'resolutionId',
);

export const deleteResolution = generateDeleteDocumentHelper();

export const getResolutionsPath = (generalAssemblyId: string): string =>
  `generalAssemblies/${generalAssemblyId}/resolutions`;
