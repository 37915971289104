import { chain, sum } from 'lodash';

import {
  AllocationKey,
  CoOwner,
  CoOwnerPublicInfos,
  Lot,
  VoteMethod,
  CoOwnersTantiemesAndCount,
} from '../types';

export const getLotsTantiemes = (
  lots: Lot[],
  allocationKeyId?: string,
): number =>
  sum(
    lots
      .filter(
        (lot) => !allocationKeyId || lot.allocationKeyId === allocationKeyId,
      )
      .map(({ tantiemes }) => tantiemes),
  );

export const getCoOwnerTantiemes = (
  coOwner: CoOwner | CoOwnerPublicInfos,
  allocationKeyId?: string,
): number => getLotsTantiemes(coOwner.lots, allocationKeyId);

export const getCoOwnersTantiemes = (
  coOwners: Record<string, CoOwner | CoOwnerPublicInfos>,
  allocationKeyId?: string,
  voteMethods?: VoteMethod[],
): number =>
  chain(coOwners)
    .values()
    .filter(
      ({ realTimeVoteMethod }) =>
        !voteMethods || voteMethods.includes(realTimeVoteMethod as VoteMethod),
    )
    .map('lots')
    .flatten()
    .filter(
      (lot) => !allocationKeyId || lot.allocationKeyId === allocationKeyId,
    )
    .map('tantiemes')
    .sum()
    .value();

export const getAllocationKeysTantiemes = (
  allocationKeys: Record<string, AllocationKey>,
): number => chain(allocationKeys).values().map('tantiemes').sum().value();

export const getPrimaryAllocationKeyTantiemes = (
  allocationKeys: Record<string, AllocationKey>,
): number =>
  chain(allocationKeys)
    .values()
    .filter(({ isPrimaryKey }) => isPrimaryKey === true)
    .map('tantiemes')
    .sum()
    .value();

export const getCoOwnerTantiemesAndCountByAllocationKeys = (
  coOwners: Record<string, CoOwner | CoOwnerPublicInfos>,
  allocationKeys: Record<string, AllocationKey>,
  voteMethods?: VoteMethod[],
): Record<string, CoOwnersTantiemesAndCount> => {
  const tantiemes: Record<string, CoOwnersTantiemesAndCount> = {};

  Object.values(allocationKeys).map(
    ({ allocationKeyId }) =>
      (tantiemes[allocationKeyId] = {
        totalTantiemes: getCoOwnersTantiemes(coOwners, allocationKeyId),
        representedTantiemes: getCoOwnersTantiemes(
          coOwners,
          allocationKeyId,
          voteMethods,
        ),
        totalCoOwnersCount: getCoOwnersByVoteMethod(coOwners, allocationKeyId),
        representedCoOwnersCount: getCoOwnersByVoteMethod(
          coOwners,
          allocationKeyId,
          voteMethods,
        ),
      }),
  );

  return tantiemes;
};

export const getCoOwnersByVoteMethod = (
  coOwners: Record<string, CoOwner | CoOwnerPublicInfos>,
  byAllocationKeyId: string,
  voteMethods?: VoteMethod[],
): number =>
  Object.values(coOwners).filter(
    ({ lots, realTimeVoteMethod }) =>
      (!voteMethods ||
        voteMethods.includes(realTimeVoteMethod as VoteMethod)) &&
      lots.some(({ allocationKeyId }) => allocationKeyId === byAllocationKeyId),
  ).length;
