import * as yup from 'yup';
import { set as createHelper } from '@nandorojo/swr-firestore';
import { A } from 'ts-toolbelt';
import { v4 as uuid } from 'uuid';
import { omitBy, isUndefined } from 'lodash';

import { MakeUndefinableKeysOptional } from 'types/utils';

export const generateCreateDocumentHelper = <
  Schema extends yup.AnyObjectSchema,
  DocumentIdKey extends string,
  OmitKeys extends
    | readonly (keyof yup.Asserts<Schema>)[]
    | undefined = undefined,
>(
  schema: Schema,
  documentIdKey: DocumentIdKey,
  omitKeys?: OmitKeys,
): ((
  collectionPath: string,
  values: MakeUndefinableKeysOptional<
    Omit<
      A.Compute<yup.Asserts<Schema>>,
      OmitKeys extends readonly (keyof yup.Asserts<Schema>)[]
        ? OmitKeys[number]
        : DocumentIdKey
    >
  >,
) => Promise<string>) => {
  const createOmitKeys = omitKeys ?? [documentIdKey];

  const create = async (
    collectionPath: string,
    values: MakeUndefinableKeysOptional<
      Omit<A.Compute<yup.Asserts<Schema>>, typeof createOmitKeys[number]>
    >,
  ) => {
    const documentId =
      (values as Record<string, unknown>)[documentIdKey] ?? uuid();

    let documentValues;
    try {
      documentValues = await schema.validate(
        {
          [documentIdKey]: documentId,
          ...values,
        },
        { abortEarly: false },
      );
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.error('Schema validation errors:', {
          errors: error.errors,
          inner: error.inner,
          path: error.path,
          value: error.value,
          id: documentId,
        });
      }
      throw error;
    }

    await createHelper(
      `${collectionPath}/${documentId}`,
      omitBy(documentValues, isUndefined),
    );

    return documentId;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return create as any;
};
