import { deleteDocument as deleteHelper } from '@nandorojo/swr-firestore';

export const generateDeleteDocumentHelper = (): ((
  collectionPath: string,
  documentId: string,
) => Promise<void>) => {
  const deleteDocument = (collectionPath: string, documentId: string) =>
    deleteHelper(`${collectionPath}/${documentId}`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return deleteDocument as any;
};
