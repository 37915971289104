import * as yup from 'yup';

import { ValidationErrorCode } from '../types/yup';

const { MISSING, INVALID_TYPE } = ValidationErrorCode;

export const lotSchema = yup.object({
  lotId: yup.string().required({ field: 'lotId', code: MISSING }),
  allocationKeyId: yup
    .string()
    .required({ field: 'allocationKeyId', code: MISSING }),
  tantiemes: yup
    .number()
    .required({ field: 'tantiemes', code: MISSING })
    .typeError({
      field: 'tantiemes',
      code: INVALID_TYPE,
    }),
});
