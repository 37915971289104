import * as yup from 'yup';

import {
  VoteMethod,
  ValidationErrorCode,
  CoOwnerEventType,
  NotificationStatus,
} from '../types';

import { lotSchema } from './lot';
import { voteSchema } from './vote';

const { MISSING, INVALID } = ValidationErrorCode;

const notificationStatusSchema = yup
  .string()
  .oneOf(Object.values(NotificationStatus));

export const coOwnerSchema = yup.object({
  coOwnerId: yup.string().required({ field: 'coOwnerId', code: MISSING }),
  userId: yup.string(),
  civility: yup.string(),
  name: yup.string().required({ field: 'name', code: MISSING }),
  address: yup
    .array()
    .of(yup.string().required())
    .min(1, { field: 'address', code: MISSING })
    .required({ field: 'address', code: MISSING }),
  postalCode: yup.string(),
  city: yup.string(),
  country: yup.string(),
  phoneNumber: yup.string(),
  email: yup.string().email({ field: 'email', code: INVALID }),
  convocationStatus: notificationStatusSchema,
  convocationStatusReason: yup.string(),
  lots: yup.array().of(lotSchema).required({ field: 'lots', code: MISSING }),
  announcedVoteMethod: yup
    .string()
    .oneOf(Object.values(VoteMethod))
    .default(VoteMethod.UNKNOWN),
  realTimeVoteMethod: yup
    .string()
    .oneOf(Object.values(VoteMethod))
    .default(VoteMethod.UNKNOWN),
  lastDetectedVoteMethod: yup
    .string()
    .oneOf(Object.values(VoteMethod))
    .default(VoteMethod.UNKNOWN),
  loginCode: yup.string(),
  signature: yup.string(),
  representedBy: yup.string(),
  lastDetectedRepresentedBy: yup.string(),
  events: yup.array().of(
    yup.object({
      date: yup.string().required(),
      type: yup.string().oneOf(Object.values(CoOwnerEventType)).required(),
      representedBy: yup.string(),
      notificationStatus: notificationStatusSchema,
      notificationStatusReason: yup.string(),
    }),
  ),
  isExternalRepresentative: yup.boolean().default(false),
  askSpeechTime: yup.string(),
  postalVotesImportDate: yup.string(),
  signatureTranscript: yup.string(),
  votes: yup.array().of(voteSchema),
});

export const coOwnerPublicFields = [
  'coOwnerId',
  'civility',
  'name',
  'lots',
  'realTimeVoteMethod',
  'representedBy',
  'askSpeechTime',
  'signatureTranscript',
] as [
  'coOwnerId',
  'civility',
  'name',
  'lots',
  'realTimeVoteMethod',
  'representedBy',
  'askSpeechTime',
  'signatureTranscript',
];

export const isCoOwnerPublicField = (
  field: string,
): field is typeof coOwnerPublicFields[number] =>
  coOwnerPublicFields.includes(field as typeof coOwnerPublicFields[number]);

export const coOwnerPublicInfosSchema = coOwnerSchema
  .pick(coOwnerPublicFields)
  .shape({
    generalAssemblyId: yup.string().uuid().required(),
  });
