import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useUserState } from 'hooks/useUser';
import { Paths } from 'components/utils/Routing';
import { UserClaims } from 'backend/types/userClaims';
import { fuego } from 'services/firebase/fuego';

export const Auth: React.FC = ({ children }) => {
  const { push } = useHistory();
  const location = useLocation();
  const [user, setUser] = useUserState();

  useEffect(() => {
    const unsubscribe = fuego.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        setUser(user);
        return;
      }

      try {
        const { token, claims } = await user.getIdTokenResult(true);
        setUser({ ...user, token, claims: claims as UserClaims });
      } catch (error) {
        console.error('Error getting user claims:', error);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  useEffect(() => {
    if (
      user === null &&
      location.pathname !== Paths.LOGIN &&
      location.pathname !== Paths.REGISTRATED_LOGIN
    ) {
      const loginUrl = new URL([window.location.origin, Paths.LOGIN].join(''));

      const wasLoggedIn = localStorage.getItem('wasLoggedIn') === 'true';

      if (
        !wasLoggedIn &&
        location.pathname &&
        location.pathname !== '/' &&
        location.href &&
        location.origin
      ) {
        const b64PrevPath = btoa(location.href.replace(location.origin, ''));
        loginUrl.searchParams.append('prevPath', b64PrevPath);
      }

      localStorage.setItem('wasLoggedIn', 'false');

      push([loginUrl.pathname, loginUrl.search].join(''));
    }
  }, [user, push, location]);

  useEffect(() => {
    if (user?.claims) {
      localStorage.setItem('wasLoggedIn', 'true');
    }
  }, [user?.claims]);

  if (user === undefined) {
    return null;
  }

  return <>{children}</>;
};
