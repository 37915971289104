import * as yup from 'yup';

import {
  ResolutionType,
  ResolutionMajorityArticle,
  ResolutionVoteStatus,
  ResolutionVoteResult,
} from '../types';
import { isCandidacyResolution, isCommentResolution } from '../utils';

export const CUSTOM_CANDIDATE = 'custom';

export const resolutionSchema = yup.object({
  resolutionId: yup.string().required(),
  resolutionNumber: yup
    .array()
    .of(yup.number().integer().min(1).required())
    .min(1)
    .max(2)
    .required(),
  title: yup.string().required(),
  allocationKeyId: yup.string().when('type', {
    is: isCommentResolution,
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  type: yup.string().oneOf(Object.values(ResolutionType)).required(),
  candidatingCoOwnerId: yup.string().when('type', {
    is: isCandidacyResolution,
    then: yup.string().required(),
  }),
  customCandidate: yup.string().when('candidatingCoOwnerId', {
    is: CUSTOM_CANDIDATE,
    then: yup.string().required(),
  }),
  majorityArticle: yup.string().when('type', {
    is: isCommentResolution,
    then: yup.string().oneOf(Object.values(ResolutionMajorityArticle)),
    otherwise: yup
      .string()
      .oneOf(Object.values(ResolutionMajorityArticle))
      .required(),
  }),
  content: yup.string().when('type', {
    is: isCommentResolution,
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  comment: yup.string(),
  voteStatus: yup
    .string()
    .oneOf(Object.values(ResolutionVoteStatus))
    .default(ResolutionVoteStatus.PENDING),
  votingCoOwnersIds: yup.array().of(yup.string().required()),
  votingCoOwnersTantiemes: yup.number().default(0),
  coOwnersInFavor: yup.number().default(0),
  coOwnersAgainst: yup.number().default(0),
  coOwnersAbstention: yup.number().default(0),
  tantiemesInFavor: yup.number().default(0),
  tantiemesAgainst: yup.number().default(0),
  tantiemesAbstention: yup.number().default(0),
  voteResult: yup.string().oneOf(Object.values(ResolutionVoteResult)),
  voteOpeningDate: yup.string(),
  voteClosureDate: yup.string(),
  manualForceSetVoteResult: yup.boolean(),
  votedCoOwnersIds: yup.array().of(yup.string().required()),
  votesIds: yup.array().of(yup.string().required()),
  isSecondReading: yup.boolean(),
  secondReadingResolutionId: yup.string().uuid(),
});
