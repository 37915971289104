import { allocationKeySchema } from 'backend/schemas/allocationKey';
import { AllocationKey } from 'backend/types/allocationKey';

import {
  generateCreateDocumentHelper,
  generateDeleteDocumentHelper,
  generateGetCollection,
  generateUpdateDocumentHelper,
} from './utils';

export const createAllocationKey = generateCreateDocumentHelper(
  allocationKeySchema,
  'allocationKeyId',
  [],
);

export const updateAllocationKey = generateUpdateDocumentHelper(
  allocationKeySchema,
  'allocationKeyId',
);

export const getAllocationKeys =
  generateGetCollection<AllocationKey>('allocationKeyId');

export const deleteAllocationKey = generateDeleteDocumentHelper();
