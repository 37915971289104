import { getCollection as getCollectionHelper } from '@nandorojo/swr-firestore';
import { keyBy } from 'lodash';

export const generateGetCollection = <T extends Record<string, unknown>>(
  documentIdKey: string,
): ((path: string) => Promise<Record<string, T>>) => {
  const getCollection = async (path: string) => {
    const data = await getCollectionHelper(path);
    return data ? keyBy(data, documentIdKey) : undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return getCollection as any;
};
