import { coOwnerSchema } from 'backend/schemas/';
import { CoOwner } from 'backend/types/coOwner';

import {
  generateCreateDocumentHelper,
  generateDeleteDocumentHelper,
  generateGetCollection,
} from './utils';
import { generateUpdateDocumentHelper } from './utils/generateUpdateDocumentHelper';

export const createCoOwner = generateCreateDocumentHelper(
  coOwnerSchema,
  'coOwnerId',
  ['announcedVoteMethod', 'realTimeVoteMethod', 'lastDetectedVoteMethod'],
);

export const getCoOwners = generateGetCollection<CoOwner>('coOwnerId');

export const updateCoOwner = generateUpdateDocumentHelper(
  coOwnerSchema,
  'coOwnerId',
);

export const deleteCoOwner = generateDeleteDocumentHelper();
