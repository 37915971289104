import * as yup from 'yup';
import { revalidateDocument } from '@nandorojo/swr-firestore';
import { A } from 'ts-toolbelt';
import { mapValues, isUndefined } from 'lodash';

import { validatePartial } from 'schemas/utils';
import { fuego } from 'services/firebase/fuego';
import { FirestoreValues } from './types';

export const generateUpdateDocumentHelper = <
  Schema extends yup.AnyObjectSchema,
  DocumentIdKey extends string,
  OmitKeys extends
    | readonly (keyof yup.Asserts<Schema>)[]
    | undefined = undefined,
>(
  schema: Schema,
  documentIdKey: DocumentIdKey,
  omitKeys?: OmitKeys,
): ((
  collectionPath: string,
  documentId: string,
  values: FirestoreValues<
    Partial<
      Omit<
        A.Compute<yup.Asserts<Schema>>,
        OmitKeys extends readonly (keyof yup.Asserts<Schema>)[]
          ? OmitKeys[number]
          : DocumentIdKey
      >
    >
  >,
) => Promise<void>) => {
  const updateOmitKeys = omitKeys ?? [documentIdKey];

  const updateDocument = async (
    collectionPath: string,
    documentId: string,
    values: Partial<
      Omit<A.Compute<yup.Asserts<Schema>>, typeof updateOmitKeys[number]>
    >,
  ) => {
    const documentUpdates = validatePartial(schema, values);

    await fuego.db.doc(`${collectionPath}/${documentId}`).update(
      mapValues(documentUpdates, (value) => {
        if (isUndefined(value)) {
          // delete field in a document
          // cf. https://firebase.google.com/docs/firestore/manage-data/delete-data#fields
          return fuego.firestore.FieldValue.delete();
        }
        return value;
      }) as A.Compute<yup.Asserts<Schema>>,
    );

    await revalidateDocument(`${collectionPath}/${documentId}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return updateDocument as any;
};
